/*Permission Numbers
59: General Access
58: Edit Permissions
60: Manage Permissions
*/

import './App.css';
import axios from 'axios';
import customAxios from './axiosConfig'
import React, { useEffect, useState, useRef, useCallback, Props } from 'react';
import ReactDraggable from 'react-draggable';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  SwipeableDrawer,
  Drawer,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
  Divider,
  Popover,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Fade,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  useTheme,
  useMediaQuery,
  Collapse,
  Link

} from '@mui/material';

import { Autocomplete } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUploadOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import BookIcon from '@mui/icons-material/Book';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import MuiAlert from '@mui/material/Alert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ChevronDown } from 'react-bootstrap-icons';

import { motion, Reorder, useDragControls, useTransform, useMotionValue, MotionValue, animate, color, useForceUpdate } from "framer-motion"

const RootStyle = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: '#f0f0f0',
  overflow: 'scroll',
}));

const DrawerStyle = styled('div')({
  height: '90vh',
  width: '100vw',
  overflow: 'auto',
});

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#28a745', // Your primary green color
      dark: '#28a745',
    },
    secondary: {
      main: '#f44336', // Your secondary red color
      dark: '#f44336',
    },
    accent: {
      main: '#ffffff',
      dark: '#ffffff',
    },
    background: {
      default: '#ffffff',
      paper: '#f0f0f0',
    },
    text: {
      primary: '#333333',
      secondary: '#666666',
    },
    // Placeholder light theme values
    light: {
      background: '#ffffff',
      text: '#333333',
    },
    // Placeholder dark theme values
    dark: {
      background: '#333333',
      text: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#28a745',
        },
      },
    },
  },
});

function App() {

  const [showOrderList, setShowOrderList] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isNewOrder, setIsNewOrder] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [showApprovalPending, setShowApprovalPending] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [selectedOrderView, setSelectedOrderView] = useState(null);
  const [selectedIsTemplate, setSelectedIsTemplate] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <>
          {showLogo ? <Logo /> : null}
          <AuthComponent
            showOrderList={showOrderList}
            setShowOrderList={setShowOrderList}
            permissions={permissions}
            setPermissions={setPermissions}
            setShowApprovalPending={setShowApprovalPending}
            setShowLogo={setShowLogo}
          />
          {showHeader ? <HeaderComponent permissions={permissions} /> : null}
          {showOrderList ? (
            <div style={{
              height: 'calc(100vh - 10%)', // Subtract header height if present
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column'
            }}>
                <OrderListComponent
                  showOrderList={showOrderList}
                  setShowOrderList={setShowOrderList}
                  permissions={permissions}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  orderList={orderList}
                  setOrderList={setOrderList}
                  isNewOrder={isNewOrder}
                  setIsNewOrder={setIsNewOrder}
                  selectedOrderView={selectedOrderView}
                  setSelectedOrderView={setSelectedOrderView}
                  templateList={templateList}
                  setTemplateList={setTemplateList}
                  selectedIsTemplate={selectedIsTemplate}
                  setSelectedIsTemplate={setSelectedIsTemplate}
                />
            </div>
          ) : null}
          {showApprovalPending ? <ApprovalPendingComponent /> : null}
          {selectedItem !== null ? (
            <div style={{ overflow: "hidden" }}>
              <OrderViewerComponent
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                orderList={orderList}
                setOrderList={setOrderList}
                permissions={permissions}
                isNewOrder={isNewOrder}
                setIsNewOrder={setIsNewOrder}
                selectedIsTemplate={selectedIsTemplate}
                setSelectedIsTemplate={setSelectedIsTemplate}
                templateList={templateList}
              />
            </div>
          ) : null}
          {selectedOrderView !== null ? (
            <div style={{ overflow: "hidden" }}>
              <OrderBinderComponent
                orderList={orderList}
                selectedOrderView={selectedOrderView}
                setSelectedOrderView={setSelectedOrderView}
              />
            </div>
          ) : null}
        </>
      </div>
    </ThemeProvider>
  );
}

export default App;

const OrderBinderComponent = ({ orderList, selectedOrderView, setSelectedOrderView }) => {

  const [title, setTitle] = useState('');
  const [currentItem, setCurrentItem] = useState(2);
  const [nextItemText, setNextItemText] = useState('');
  const [imageLoading, setImageLoading] = useState(null);
  const [options, setOptions] = useState(null);
  const [forcedPages, setForcedPages] = useState(null);
  const [pagesDownloading, setPagesDownloading] = useState(false);

  const canGoBackward = currentItem > 2;
  const canGoForward = currentItem <= orderList[selectedOrderView]?.content.render_pipeline.length - 2;

  const iOS =
    typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  async function getPages(songId, elementIndex) {
    setPagesDownloading(true);
    setImageLoading(true);
    const response = await customAxios.post('/jubilee/get_song_pages/', { 'id': songId });
    orderList[selectedOrderView].content.render_pipeline[elementIndex].content.img_srcs = response.data.content.img_srcs;
    setImageLoading(false);
    setPagesDownloading(false);
  }

  async function getSongList() {
    const songList = await customAxios.get('/jubilee/get_songs/');
    const processedSongList = songList.data.songs.map(song => ({
      ...song,
      fields: {
        ...song.fields,
        title: song.fields.title.replace(/'/g, "'")
      }
    }));

    setOptions(processedSongList);
  }

  const DraggableBox = ({ constraintsRef, boxText }) => {
    const [isDragging, setIsDragging] = useState(false);
    const boxRef = useRef(null);

    const loadPosition = () => {
      const savedPosition = localStorage.getItem('global-box-position');
      return savedPosition ? JSON.parse(savedPosition) : { x: 100, y: 100 };
    };

    const [position, setPosition] = useState(loadPosition);

    useEffect(() => {
      localStorage.setItem('global-box-position', JSON.stringify(position));
    }, [position]);

    useEffect(() => {
      if (boxRef.current) {
        const rect = constraintsRef.current.getBoundingClientRect();
        const deltaX = (position.x + (rect.width / 2)) - (boxRef.current.getBoundingClientRect().width / 2);
        const deltaY = (position.y + (rect.height / 2)) - (boxRef.current.getBoundingClientRect().height / 2);

        setPosition({ x: deltaX < 0 ? position.x - deltaX : position.x, y: deltaY < 0 ? position.y - deltaY : position.y })
      }
    }, [boxText])

    const handleDragEnd = (event, info) => {
      if (constraintsRef.current) {
        const rect = constraintsRef.current.getBoundingClientRect();
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;

        // Convert from top-left origin to center origin
        const newX = info.point.x - rect.left - centerX;
        const newY = info.point.y - rect.top - centerY;

        setPosition({ x: newX, y: newY });
      }
      setIsDragging(false);
    };

    return (
      <motion.div
        drag
        dragMomentum={false}
        ref={boxRef}
        whileDrag={{ scale: 1.1 }}
        dragElastic={0.1}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={handleDragEnd}
        dragConstraints={constraintsRef}
        initial={position}
        animate={position}
        style={{
          position: 'absolute',
          padding: '1vw 2vw',
          background: '#28a745',
          borderRadius: '0.5vw',
          cursor: 'grab',
          boxShadow: isDragging ? '0 0.5vw 1vw rgba(0, 0, 0, 0.2)' : '0 0.25vw 0.5vw rgba(0, 0, 0, 0.1)',
          transition: 'box-shadow 0.3s ease',
          maxWidth: '80vw',
          maxHeight: '80vh',
          overflow: 'auto',
        }}
      >
        <span
          style={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: 'clamp(1rem, 2vw, 2rem)',
            lineHeight: '1.2',
            display: 'block',
            wordBreak: 'break-word',
          }}
        >
          {boxText}
        </span>
      </motion.div>
    );
  };

  const BookIconButton = () => {
    const [bookAnchorEl, setBookAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleClick = (event) => {
      setBookAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setBookAnchorEl(null);
    };

    async function getPages(songId) {
      setPagesDownloading(true);
      const response = await customAxios.post('/jubilee/get_song_pages/', { 'id': songId });
      setForcedPages(response.data.content.img_srcs);
      setPagesDownloading(false);
    }

    const handleOptionSelect = (option) => {
      setSelectedOption(option);
      handleClose();
      getPages(option.pk)
    };

    return (
      <>
        <IconButton
          aria-controls="book-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <BookIcon />
        </IconButton>
        {options &&
          <Menu
            id="book-menu"
            anchorEl={bookAnchorEl}
            open={Boolean(bookAnchorEl)}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'book-button',
            }}
          >
            {options.map((option, index) => (
              <MenuItem
                key={index}
                selected={option === selectedOption}
                onClick={() => handleOptionSelect(option)}
              >
                <Typography variant="inherit">{option.fields.song_number > -1 ? option.fields.song_number : ''} {option.fields.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        }
      </>
    );
  };

  const OrderListIconButton = () => {
    const [localAnchorEl, setLocalAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [orderOptions, setOrderOptions] = useState([]);

    const handleClick = (event) => {
      setLocalAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setLocalAnchorEl(null);
    };

    const handleOptionSelect = (option) => {
      setSelectedOption(option);
      setCurrentItem(option);
      if (orderList[selectedOrderView].content.render_pipeline[option].type === 'song') {
        setForcedPages(orderList[selectedOrderView].content.render_pipeline[option].content.img_srcs)
      }
      else {
        setForcedPages(null);
      }
      handleClose();
    };

    useEffect(() => {
      if (selectedOrderView !== null && orderList[selectedOrderView]) {
        const tempArray = orderList[selectedOrderView].content.render_pipeline
          .map((item, index) => {
            if (item.type !== 'header' && item.type !== 'date') {
              let truTitle = '';
              switch (item.type) {
                case 'song':
                  truTitle = `${item.content.song_number > -1 ? item.content.song_number : ''} ${item.content.title}`;
                  break;
                case 'text':
                  truTitle = item.content.text;
                  break;
                case 'text_w_target':
                  truTitle = `${item.content.text}: ${item.content.target}`;
                  break;
                case 'announcement':
                  truTitle = `${item.content.text}: ${item.content.date}`;
                  break;
                case 'template':
                  truTitle = `Template of Type '${item.content.type}'`
                  break;
                default:
                  return null;
              }
              return { index, truTitle, type: item.type };
            }
            return null;
          })
          .filter(Boolean);
        setOrderOptions(tempArray);
      }
    }, [selectedOrderView, orderList]);

    return (
      <>
        <IconButton
          aria-controls="order-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        {orderOptions.length > 0 &&
          <Menu
            id="order-menu"
            anchorEl={localAnchorEl}
            open={Boolean(localAnchorEl)}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'order-button',
            }}
          >
            {orderOptions.map((option) => (
              <MenuItem
                key={option.index}
                selected={option.index === selectedOption}
                onClick={() => handleOptionSelect(option.index)}
              >
                <Typography variant="inherit">{option.truTitle}</Typography>
              </MenuItem>
            ))}
          </Menu>
        }
      </>
    );
  };


  useEffect(() => {
    if (selectedOrderView !== null) {
      setTitle(orderList[selectedOrderView].title);
      let flagFound = false;
      orderList[selectedOrderView].content.render_pipeline.forEach((element, elementIndex) => {
        if (currentItem === null && (element.type !== 'header' && element.type !== 'date') && !flagFound) {
          setCurrentItem(elementIndex);
          flagFound = true;
        }
        if (element.type == 'song') {
          getPages(element.content.id, elementIndex);
        }
      });
      getSongList();
    }
  }, [selectedOrderView])

  function handleBackButton() {
    const newItems = orderList[selectedOrderView].content.render_pipeline.map(obj => {
      if (obj.content && obj.content.img_srcs) {
        const { img_srcs, ...restContent } = obj.content;
        return { ...obj, content: restContent };
      }
      return obj;
    });
    orderList[selectedOrderView].content.render_pipeline = newItems
    setSelectedOrderView(null);
  }

  function handleOrderForward() {
    if (currentItem < orderList[selectedOrderView].content.render_pipeline.length - 1) {
      setCurrentItem(currentItem + 1);
      setForcedPages(null);
    }
    else {
      setForcedPages(null);
      setNextItemText(null);
    }
  }

  function handleOrderBackwards() {
    if (currentItem > 2) {
      setCurrentItem(currentItem - 1);
      setForcedPages(null);
    }
    else {
      setForcedPages(null);
      setNextItemText(null);
    }
  }

  const PageViewComponent = ({ imageLoading, forcedPages, currentItem, orderList, selectedOrderView, pagesDownloading }) => {
    const [isVertical, setIsVertical] = useState(true);
    const [isSong, setIsSong] = useState(true);
    const [pages, setPages] = useState([]);
    const [displayText, setDisplayText] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
      const handleResize = () => {
        setIsVertical(window.innerHeight > window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
      console.log('Pages are downloading ',pagesDownloading)
      if (currentItem === null) {
        setIsSong(true);
        setCurrentItem(2);
      }
    }, [pagesDownloading])

    useEffect(() => {
      if (!isVertical && currentPage % 2 !== 0) {
        setCurrentPage(currentPage - 1);
      }
    }, [isVertical, currentPage]);

    useEffect(() => {
      if (currentItem !== null && orderList && orderList[selectedOrderView]) {
        const item = orderList[selectedOrderView].content.render_pipeline[currentItem];
        if (item) {
          switch (item.type) {
            case 'song':
              if (item.content.img_srcs) {
                setPages(item.content.img_srcs);
                setIsSong(true);
              }
              break;
            case 'text':
            case 'text_w_target':
            case 'announcement':
            case 'template':
              setIsSong(false);
              setDisplayText(getDisplayText(item));
              break;
          }
        }
      }
    }, [currentItem, orderList, selectedOrderView]);

    useEffect(() => {
      if (forcedPages) {
        setPages(forcedPages);
        setIsSong(true);
      }
    }, [forcedPages]);

    const getDisplayText = (item) => {
      switch (item.type) {
        case 'text':
          return item.content.text;
        case 'text_w_target':
          return `${item.content.text}: ${item.content.target}`;
        case 'announcement':
          return `${item.content.text}: ${item.content.date}`;
        case 'template':
          return `Template of type '${item.content.type}'`
        default:
          return '';
      }
    };

    const handlePrevPage = () => {
      setCurrentPage((prevPage) => Math.max(prevPage - (isVertical ? 1 : 2), 0));
    };

    const handleNextPage = () => {
      setCurrentPage((prevPage) => {
        const nextPage = prevPage + (isVertical ? 1 : 2);
        return Math.min(nextPage, isVertical ? pages.length - 1 : (pages.length % 2 === 0 ? pages.length - 2 : pages.length - 1));
      });
    };

    const showPrevButton = currentPage > 0;
    const showNextButton = isVertical
      ? currentPage < pages.length - 1
      : currentPage < (pages.length % 2 === 0 ? pages.length - 2 : pages.length - 1);

    const PageViewer = () => (
      <div ref={containerRef} style={{ position: 'relative', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <DraggableBox constraintsRef={containerRef} boxText={orderList[selectedOrderView].content.render_pipeline[currentItem] ? orderList[selectedOrderView].content.render_pipeline[currentItem].content.verse_order : ''} />
        {showPrevButton && (
          <IconButton
            onClick={handlePrevPage}
            style={{
              position: 'absolute',
              left: 10,
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(0,0,0,0.5)',
              color: 'white',
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
          {pages.length > 0 && (
            <img
              src={pages[currentPage]}
              style={{
                maxHeight: '100%',
                maxWidth: isVertical ? '100%' : '50%',
                objectFit: 'contain',
              }}
              alt={`Page ${currentPage + 1}`}
            />
          )}
          {!isVertical && pages.length > 1 && currentPage < pages.length - 1 && (
            <img
              src={pages[currentPage + 1]}
              style={{
                maxHeight: '100%',
                maxWidth: '50%',
                objectFit: 'contain',
              }}
              alt={`Page ${currentPage + 2}`}
            />
          )}
        </div>
        {showNextButton && (
          <IconButton
            onClick={handleNextPage}
            style={{
              position: 'absolute',
              right: 10,
              top: '50%',
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(0,0,0,0.5)',
              color: 'white',
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
        )}
      </div>
    );

    const TextViewer = ({ displayText }) => (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <span style={{
          color: '#FFFFFF',
          fontSize: '5vw',
          textAlign: 'center',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden'
        }}>
          {displayText}
        </span>
      </div>
    );

    return (
      <div style={{ width: '100%', height: '100%' }}>
        {pagesDownloading ? (
          <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={60} style={{ color: '#28a745' }} />
          </div>
        ) : isSong ? (
          <PageViewer />
        ) : (
          <TextViewer displayText={displayText} />
        )}
      </div>
    );
  };

  return (
    <Drawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      key={'viewer_drawer'}
      anchor='right'
      open={selectedOrderView !== null}
      onClose={() => setSelectedOrderView(null)}
      onOpen={null}
      style={{ backgroundColor: 'transparent' }}
    >

      <div style={{ backgroundColor: 'rgb(60,60,60)', width: '100vw', height: '100vh', overflow: 'hidden', overscrollBehavior: 'contain', display: 'flex', flexDirection: 'column' }}>
        <AppBar position="static" style={{ backgroundColor: '#28a745' }}>
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton edge="start" color="accent" aria-label="back">
              <ArrowBackIcon onClick={handleBackButton} />
            </IconButton>

            <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h6" component="div" style={{ textAlign: 'center' }}>
                <span>{title}</span>

              </Typography>
            </div>
            <>
              <OrderListIconButton />
              <BookIconButton />
              {canGoBackward && (
                <IconButton edge="start" color="accent" aria-label="backward" onClick={handleOrderBackwards}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              {canGoForward && (
                <IconButton edge="start" color="accent" aria-label="forward" onClick={handleOrderForward}>
                  <ArrowForwardIcon />
                </IconButton>
              )}
            </>
            <div style={{ width: 48 }} /> {/* Spacer to balance the layout */}
          </Toolbar>
        </AppBar>
        <PageViewComponent
          imageLoading={imageLoading}
          forcedPages={forcedPages}
          currentItem={currentItem}
          orderList={orderList}
          selectedOrderView={selectedOrderView}
          pagesDownloading={pagesDownloading}
        />
      </div>
    </Drawer >
  );
}

const AddToHomeComponent = () => {
  return (
    <div style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', backgroundColor: '#6ec069', overflowY: 'hidden' }}>
      <div style={{ flex: '0 1 auto', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
        <img src="gust_logo_1000x1000.png" alt="Logo" style={{ maxWidth: '100%', maxHeight: '20vh', aspectRatio: '1', backgroundColor: 'rgb(80,80,80,.5)', borderRadius: '10px' }} />
      </div>
      <div style={{ flex: '1 1 auto', overflowY: 'auto', background: 'linear-gradient(180deg, #6ec069, #d7f0d7)', padding: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
          <span style={{ fontSize: '25px' }}>Tap the Share Icon</span>
          <img src='step1.png' style={{ maxWidth: '80%', borderRadius: '10px' }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
          <span style={{ fontSize: '25px' }}>Press "Add to Home Screen"</span>
          <img src='step2.png' style={{ maxWidth: '80%', borderRadius: '10px' }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
          <span style={{ fontSize: '25px' }}>Tap "Add"</span>
          <img src='step3.png' style={{ maxWidth: '80%', borderRadius: '10px' }} />
        </div>
      </div>
    </div>

  );
}

const ApprovalPendingComponent = () => {
  const containerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'

  };

  async function handleLogOut() {

    let config = {
      headers: {}
    };
    if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== undefined) {

      config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
    }
    try {
      const response = await axios.post('/jubilee/oos_signout/', null, config);
      localStorage.setItem('accessToken', null);
      window.location = '/';
    }
    catch (error) {
      console.log(error)
    }

  }

  return (
    <div style={containerStyle}>
      <span>Please wait for your admin to approve your account</span>
      <Button variant="contained" onClick={() => handleLogOut()}>
        Log Out
      </Button>
    </div>
  );
}

const Logo = () => {
  return (
    <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute' }}>
      <img src="gust_logo_1000x1000.png" alt="Logo" style={{ maxWidth: '100vw', maxHeight: '100vh', aspectRatio: '1' }} />
    </div>
  );
}


const HeaderComponent = ({ permissions }) => {
  const [settingsOpen, setSettingsOpen] = useState(false);

  const hasPermission = (id) => {
    return permissions.some(item => item.id === id);
  };

  const handleSettingsClick = () => {
    setSettingsOpen(true);
  };

  const handleSettingsClose = () => {
    setSettingsOpen(false);
  };

  return (
    <div style={{
      height: '10%',
      width: '100%',
      position: 'relative',
      top: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <img src='NHBCLogoPlant.png' style={{ height: '100%', aspectRatio: 1 }} />
      {hasPermission(60) ?
        <div style={{
          position: 'absolute',
          right: '2vw',
          top: '2vh',
        }}>
          <IconButton
            onClick={handleSettingsClick}
            style={{ padding: '0.5vmin' }}
          >
            <SettingsIcon style={{
              fontSize: 'clamp(24px, 4vmin, 40px)'
            }} />
          </IconButton>
        </div> : <div></div>
      }
      <SettingsMenu open={settingsOpen} onClose={handleSettingsClose} />
    </div>
  );
};

const getUserPermissions = async () => {
  const response = await customAxios.get('/jubilee/get_users_and_permissions/');
  return response.data;
};

const colors = {
  primary: '#28a745',
  background: 'rgb(60,60,60)',
  surface: 'rgb(80,80,80)',
  text: 'white',
};

async function getSongList() {
  const songList = await customAxios.get('/jubilee/get_songs/');
  const processedSongList = songList.data.songs.map(song => ({
    ...song,
    fields: {
      ...song.fields,
      title: song.fields.title.replace(/'/g, "'")
    }
  }));

  return processedSongList;
}

const SongListTable = () => {
  const [allSongs, setAllSongs] = useState([]);
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [deletingIds, setDeletingIds] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchSongs();
  }, []);

  useEffect(() => {
    filterSongs(searchTerm);
  }, [searchTerm, allSongs]);

  const fetchSongs = async () => {
    try {
      const processedSongList = await getSongList();
      setAllSongs(processedSongList);
      setFilteredSongs(processedSongList);
    } catch (error) {
      console.error('Error fetching songs:', error);
    }
  };

  const handleDelete = async (pkId) => {
    if (deletingIds[pkId]) {
      try {
        const response = await customAxios.post('jubilee/delete_song/', { song_id: pkId });
        if (response.data.success) {
          const updatedAllSongs = allSongs.filter(song => song.pk !== pkId);
          setAllSongs(updatedAllSongs);
          filterSongs(searchTerm, updatedAllSongs);
          setDeletingIds(prev => ({ ...prev, [pkId]: false }));
        }
      } catch (error) {
        console.error('Error deleting song:', error);
      }
    } else {
      setDeletingIds(prev => ({ ...prev, [pkId]: true }));
    }
  };

  const filterSongs = (value, songs = allSongs) => {
    if (value) {
      const filtered = songs.filter(song =>
        song.fields.title.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSongs(filtered);
    } else {
      setFilteredSongs(songs);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 800, margin: 'auto' }}>
      <TextField
        label="Search songs"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearch}
        sx={{
          mb: 2,
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: colors.text },
            '&:hover fieldset': { borderColor: colors.primary },
            '&.Mui-focused fieldset': { borderColor: colors.primary },
          },
          '& .MuiInputLabel-root': { color: colors.text },
          '& .MuiInputBase-input': { color: colors.text },
        }}
      />
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: colors.surface,
          height: 300, // Fixed height to show 5 rows (adjust if needed)
          overflow: 'auto' // Enable scrolling
        }}
      >
        <Table stickyHeader sx={{ minWidth: 300 }} aria-label="song list table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: colors.surface, color: colors.text, fontWeight: 'bold' }}>Title</TableCell>
              <TableCell align="right" sx={{ backgroundColor: colors.surface, color: colors.text, fontWeight: 'bold' }}>Song Number</TableCell>
              <TableCell align="right" sx={{ backgroundColor: colors.surface, color: colors.text, fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSongs.map((song) => (
              <TableRow key={song.pk}>
                <TableCell component="th" scope="row" sx={{ color: colors.text }}>
                  {song.fields.title}
                </TableCell>
                <TableCell align="right" sx={{ color: colors.text }}>
                  {song.fields.song_number !== -1 ? song.fields.song_number : ''}
                </TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => handleDelete(song.pk)}
                    color={deletingIds[song.pk] ? "error" : "primary"}
                    sx={{
                      backgroundColor: deletingIds[song.pk] ? 'error.main' : colors.primary,
                      color: colors.text,
                      '&:hover': {
                        backgroundColor: deletingIds[song.pk] ? 'error.dark' : colors.primary,
                      },
                    }}
                  >
                    {deletingIds[song.pk] ? "Confirm Delete" : "Delete"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const SettingsMenu = ({ open, onClose }) => {
  const [userPermissions, setUserPermissions] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [expandedUser, setExpandedUser] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchUserPermissions = async () => {
      if (open) {
        try {
          const permissions = await getUserPermissions();
          setUserPermissions(permissions);
        } catch (error) {
          console.error('Error fetching user permissions:', error);
          setSnackbar({ open: true, message: 'Failed to fetch user permissions', severity: 'error' });
        }
      }
    };

    fetchUserPermissions();
  }, [open]);

  const handlePermissionChange = async (username, permissionId, checked) => {
    console.log(`User: ${username}, Permission: ${permissionId}, Checked: ${checked}`);

    // Optimistic update
    setUserPermissions(prevState => ({
      ...prevState,
      data: prevState.data.map(user =>
        user.username === username
          ? {
            ...user,
            permissions: checked
              ? [...user.permissions, permissionId]
              : user.permissions.filter(id => id !== permissionId)
          }
          : user
      )
    }));

    try {
      // Axios POST request to change_permission route
      await customAxios.post('/jubilee/change_permission/', {
        username: username,
        permission_id: permissionId,
        has_permission: checked
      });

      setSnackbar({ open: true, message: 'Permission updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating permission:', error);
      // Revert the optimistic update
      setUserPermissions(prevState => ({
        ...prevState,
        data: prevState.data.map(user =>
          user.username === username
            ? {
              ...user,
              permissions: checked
                ? user.permissions.filter(id => id !== permissionId)
                : [...user.permissions, permissionId]
            }
            : user
        )
      }));
      setSnackbar({ open: true, message: 'Failed to update permission', severity: 'error' });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleExpandUser = (username) => {
    setExpandedUser(expandedUser === username ? null : username);
  };

  const renderUserList = () => (
    <Box sx={{ m: 2, width: 'calc(100% - 32px)' }}>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: colors.surface,
          maxHeight: 300,
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: colors.background
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.primary,
          },
          scrollbarWidth: 'thin',
          scrollbarColor: `${colors.primary} ${colors.background}`,
        }}
      >
        <List sx={{ width: '100%', bgcolor: 'transparent' }}>
          {userPermissions.data.map((user) => (
            <React.Fragment key={user.username}>
              <ListItem
                button
                onClick={() => handleExpandUser(user.username)}
                sx={{
                  borderBottom: `1px solid ${colors.background}`,
                  '&:hover': {
                    backgroundColor: `${colors.background}20`,
                  },
                }}
              >
                <ListItemText
                  primary={user.username}
                  sx={{ color: colors.text }}
                />
                {expandedUser === user.username ?
                  <ExpandLessIcon sx={{ color: colors.text }} /> :
                  <ExpandMoreIcon sx={{ color: colors.text }} />
                }
              </ListItem>
              <Collapse in={expandedUser === user.username} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {userPermissions.key_table.map((permission) => (
                    <ListItem
                      key={permission.id}
                      sx={{
                        pl: 4,
                        borderBottom: `1px solid ${colors.background}20`,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <ListItemText
                        primary={permission.name}
                        sx={{
                          color: colors.text,
                          flexGrow: 1,
                          mr: 2,
                        }}
                      />
                      <Checkbox
                        checked={user.permissions.includes(permission.id)}
                        onChange={(event) => handlePermissionChange(user.username, permission.id, event.target.checked)}
                        sx={{
                          color: colors.text,
                          '&.Mui-checked': {
                            color: colors.primary,
                          },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </TableContainer>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            backgroundColor: colors.background,
            width: { xs: '100%', sm: '80%', md: '60%' },
            maxWidth: '600px',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <AppBar position="static" sx={{ backgroundColor: colors.primary }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="back">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
              Settings
            </Typography>
            <div style={{ width: 48 }} />
          </Toolbar>
        </AppBar>
        <Box sx={{
          flexGrow: 1,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: colors.background
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.primary,
          },
          scrollbarWidth: 'thin',
          scrollbarColor: `${colors.primary} ${colors.background}`,
        }}>
          <Typography variant="h5" sx={{ m: 2, color: colors.text, borderBottom: `1px solid ${colors.primary}`, paddingBottom: 1 }}>
            Manage Permissions
          </Typography>
          {userPermissions && renderUserList()}
          <Typography variant="h5" sx={{ m: 2, mt: 4, color: colors.text, borderBottom: `1px solid ${colors.primary}`, paddingBottom: 1 }}>
            Manage Songs
          </Typography>
          <Box sx={{ m: 2, mt: 0 }}>
            <SongListTable />
          </Box>
        </Box>
      </Drawer>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
};


const CustomTextField = styled(TextField)({
  '& .MuiInput-underline:after': {
    borderBottomColor: '#28a745',
  },
});

const AuthComponent = ({ setShowOrderList, setPermissions, setShowApprovalPending, setShowLogo }) => {
  const [signInOpen, setSignInOpen] = useState(true);
  const [signUpOpen, setSignUpOpen] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [signInEmail, setSignInEmail] = useState('');
  const [signInPassword, setSignInPassword] = useState('');
  const [signUpEmail, setSignUpEmail] = useState('');
  const [signUpPassword, setSignUpPassword] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    handleSignIn(false);
  }, []);

  const toggleSignIn = () => {
    setSignInOpen(true);
    setSignUpOpen(false);
    setResetPasswordOpen(false);
  };

  const toggleSignUp = () => {
    setSignUpOpen(true);
    setSignInOpen(false);
    setResetPasswordOpen(false);
  };

  const toggleResetPassword = () => {
    setResetPasswordOpen(true);
    setSignInOpen(false);
    setSignUpOpen(false);
  };

  const handleClose = () => {
    setSignInOpen(true);
    setSignUpOpen(false);
    setResetPasswordOpen(false);
  };

  const handleCreateAccount = async () => {
    try {
      await axios.post('/jubilee/oos_signup/', {
        username: signUpEmail,
        password: signUpPassword,
      });
      setAlertMessage('Account created successfully!');
      setAlertOpen(true);
      toggleSignIn();
    } catch (error) {
      setAlertMessage('Account creation failed. Please try again.');
      setAlertOpen(true);
    }
  };

  const handleResetPassword = async () => {
    try {
      await customAxios.post('/jubilee/reset_password/', {
        email: resetEmail,
      });
      setAlertMessage('Password reset email sent. Please check your inbox.');
      setAlertOpen(true);
      toggleSignIn();
    } catch (error) {
      setAlertMessage('Password reset failed. Please try again.');
      setAlertOpen(true);
    }
  };

  const checkJWT = async () => {
    const config = {
      headers: localStorage.getItem('accessToken') ? {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      } : {}
    };

    try {
      const response = await axios.post('/jubilee/jwt_oos_signin/', null, config);
      return response;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const checkCreds = async () => {
    try {
      const response = await axios.post('/jubilee/creds_oos_signin/', {
        username: signInEmail,
        password: signInPassword
      });
      return response;
    } catch (error) {
      console.error(error);
      setAlertMessage('Sign in failed, please check credentials.');
      setAlertOpen(true);
      return null;
    }
  };

  const handleSignIn = async (showMessage) => {
    try {
      let response = await checkJWT();
      if (showMessage) {
        response = await checkCreds();
      }

      if (response && response.data.access_token) {
        localStorage.setItem('accessToken', response.data.access_token);
      }

      if (response && response.status === 200) {
        setSignInOpen(false);
        setSignUpOpen(false);
        if (JSON.parse(response.data.permissions).some(item => item.id === 59)) {
          setShowOrderList(true);
        } else {
          setShowApprovalPending(true);
        }
        setPermissions(JSON.parse(response.data.permissions));
        setShowLogo(false);
      } else {
        setShowLogo(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <div>
      <Dialog open={signInOpen} onClose={handleClose}>
        <DialogTitle>Sign In</DialogTitle>
        <DialogContent>
          <CustomTextField
            label="Email"
            fullWidth
            margin="normal"
            value={signInEmail}
            onChange={(e) => setSignInEmail(e.target.value)}
            InputLabelProps={{
              style: { color: '#28a745' },
            }}
          />
          <CustomTextField
            label="Password"
            fullWidth
            margin="normal"
            type="password"
            value={signInPassword}
            onChange={(e) => setSignInPassword(e.target.value)}
            InputLabelProps={{
              style: { color: '#28a745' },
            }}
          />
          <Link
            component="button"
            variant="body2"
            onClick={toggleResetPassword}
            style={{ marginTop: '10px' }}
          >
            Forgot Password?
          </Link>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleSignUp}>Create Account</Button>
          <Button onClick={() => handleSignIn(true)}>Sign In</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={signUpOpen} onClose={handleClose}>
        <DialogTitle>Create Account</DialogTitle>
        <DialogContent>
          <CustomTextField
            label="Email"
            fullWidth
            margin="normal"
            value={signUpEmail}
            onChange={(e) => setSignUpEmail(e.target.value)}
            InputLabelProps={{
              style: { color: '#28a745' },
            }}
          />
          <CustomTextField
            label="Password"
            fullWidth
            margin="normal"
            type="password"
            value={signUpPassword}
            onChange={(e) => setSignUpPassword(e.target.value)}
            InputLabelProps={{
              style: { color: '#28a745' },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleSignIn}>Back</Button>
          <Button onClick={handleCreateAccount}>Create Account</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={resetPasswordOpen} onClose={handleClose}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <CustomTextField
            label="Email"
            fullWidth
            margin="normal"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            InputLabelProps={{
              style: { color: '#28a745' },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleSignIn}>Back</Button>
          <Button onClick={handleResetPassword}>Reset Password</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        message={alertMessage}
      />
    </div>
  );
};

const OrderListComponent = ({ showOrderList, selectedIsTemplate, setSelectedIsTemplate, setShowOrderList, selectedOrderView, setSelectedOrderView, permissions, selectedItem, setSelectedItem, orderList, setOrderList, templateList, setTemplateList, isNewOrder, setIsNewOrder }) => {

  const [orderListAnchorEl, setOrderListAnchorEl] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
  const [gettingOrders, setGettingOrders] = useState(false);

  useEffect(() => {
    getOrderList();
  }, [])

  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#28a745',
    color: 'white',
    '&:hover': {
      backgroundColor: '#218838',
    },
    margin: '10px'
  }));

  useEffect(() => {
    if (selectedItem === null) {
      getOrderList();
    }
  }, [selectedItem])

  const getOrderList = async () => {

    let config = {
      headers: {}
    };
    if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== undefined) {

      config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
    }
    try {
      setGettingOrders(true);
      const response = await customAxios.get('/jubilee/get_orders/');
      console.log('got orders')
      setGettingOrders(false);
      response.data.orders.reverse()
      const templates = response.data.orders.filter(item => item.type === 'template');
      const orders = response.data.orders.filter(item => item.type === 'order');
      setOrderList(orders)
      setTemplateList(templates)
    }
    catch (error) {
      console.log(error)
    }
  }

  const hasPermission = (id) => {
    return permissions.some(item => item.id === id);
  };

  const handleItemClick = (index, isTemplate) => {
    setSelectedItem(index === selectedItem ? null : index);
    setSelectedIsTemplate(isTemplate);
    setIsNewOrder(false);
  };

  async function onDelete(index, isTemplate) {
    const itemId = isTemplate ? templateList[index].pk : orderList[index].pk;

    const response = await customAxios.post('/jubilee/delete_order/', { 'pk_id': itemId });
    if (response.status === 200) {
      getOrderList();
    }
  }

  const OrderListItem = ({ index, item, isTemplate }) => {

    const [loadingState, setLoadingState] = useState(0);

    const [deleteConfirmation, setDeleteConfirmation] = useState(false)

    function handleDelete(index, isTemplate) {
      if (deleteConfirmation) {
        onDelete(index, isTemplate);
        setDeleteConfirmation(false);
      }
      else {
        setDeleteConfirmation(true);
      }

    }

    async function handleDownloadClick(index, isTemplate) {
      setLoadingState(1); // Start loading

      const downloadItem = isTemplate ? templateList[index] : orderList[index];

      let orderBase64;
      try {
        orderBase64 = await customAxios.post('/jubilee/export_order/', downloadItem.content);

        // Download success
        setLoadingState(2); // Set loading state to success

        // Convert base64 to Blob
        const byteCharacters = atob(orderBase64.data.export_base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/zip' });

        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        // Create a link element and simulate click to download
        const link = document.createElement('a');
        link.href = url;
        link.download = `${orderBase64.data.filename}`; // Set desired file name
        document.body.appendChild(link);
        link.click();

        // Clean up
        URL.revokeObjectURL(url);
        document.body.removeChild(link);

        // Reset loading state after 2 seconds
        setTimeout(() => {
          setLoadingState(0); // Reset loading state
        }, 2000);
      } catch (error) {
        // Download failed
        setLoadingState(3); // Set loading state to failure
        console.error('failure:', error);

        // Reset loading state after 2 seconds
        setTimeout(() => {
          setLoadingState(0); // Reset loading state
        }, 2000);
      }

    }

    function handleOrderSelection() { //When a template is clicked on
      if (isTemplate) {
        setIsNewOrder(true);
        let newOrder = {
          pk: -1,
          title: '',
          content: {
            render_pipeline: templateList[index].content.render_pipeline
          },
          type: 'order'
        };

        setOrderList([...orderList, newOrder]);
        setSelectedIsTemplate(true);
        setSelectedItem(index);
      }
      else {
        setSelectedOrderView(index);
      }
    }

    return (<ListItem
      key={index}
      button
      selected={index === selectedItem}
      onClick={handleOrderSelection}
    >
      <ListItemText
        primary={item.title.replace(/'/g, "'")}
        style={{
          overflow: 'hidden', // Hide any overflowing text
          textOverflow: 'ellipsis', // Display ellipsis for overflowed text
          whiteSpace: 'nowrap', // Prevent wrapping of text
          maxWidth: '75%' // Set maximum width
        }}
      />

      {hasEditingPermissions && (
        <ListItemSecondaryAction>
          {!isTemplate && (
            <>
              {loadingState === 0 ? (
                <IconButton edge="end" aria-label="download" onClick={() => handleDownloadClick(index, isTemplate)}>
                  <CloudDownloadOutlined style={{ color: '#28a745' }} />
                </IconButton>
              ) : loadingState === 1 ? (
                <IconButton edge="end" aria-label="loading">
                  <CircularProgress style={{ color: '#28a745' }} size={20} />
                </IconButton>
              ) : loadingState === 2 ? (
                <IconButton edge="end" aria-label="success">
                  <CheckIcon style={{ color: '#28a745' }} />
                </IconButton>
              ) : (
                <IconButton edge="end" aria-label="failure">
                  <span style={{ textAlign: 'center', color: 'red' }}>X</span>
                </IconButton>
              )}
            </>
          )}
          <IconButton edge="end" aria-label="edit" onClick={() => handleItemClick(index, isTemplate)}>
            <EditIcon style={{ color: '#28a745' }} />
          </IconButton>
          <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index, isTemplate)}>
            {deleteConfirmation ? <DeleteIcon style={{ color: 'red' }} /> : <DeleteIcon style={{ color: '#28a745' }} />}
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>)
  }

  const handleAddOrderClick = (event) => {
    setOrderListAnchorEl(event.currentTarget);
    const rect = event.currentTarget.getBoundingClientRect();
    setAnchorPosition({ top: rect.bottom, left: rect.left });
  };

  const handleAddOrderClose = () => {
    setOrderListAnchorEl(null);
  };

  function handleNewOrder() {
    setIsNewOrder(true);
    let newOrder = {
      pk: -1,
      title: '',
      content: {
        render_pipeline: [
          { 'type': 'header', 'content': { 'header': '' } },
          { 'type': 'date', 'content': { 'date': '' } }
        ]
      },
      type: 'order'
    };

    setOrderList([...orderList, newOrder]);
    setSelectedIsTemplate(false);
    setSelectedItem(orderList.length);
  };

  async function handleLogOut() {

    let config = {
      headers: {}
    };
    if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== undefined) {

      config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
    }
    try {
      const response = await axios.post('/jubilee/oos_signout/', null, config);
      localStorage.setItem('accessToken', null);
      window.location = '/';
    }
    catch (error) {
      console.log(error)
    }

  }

  const hasEditingPermissions = hasPermission(58);
  const hasManagementPermissions = hasPermission(60);

  const sortedTemplates = [...templateList].sort((a, b) =>
    new Date(b.updated_at) - new Date(a.updated_at)
  );

  return (
    <>
      <Box sx={{ width: '100vw', margin: 0 }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          {hasEditingPermissions && (
            <div>
              <StyledButton
                variant="contained"
                onClick={handleNewOrder}
              >
                Create Order
              </StyledButton>
            </div>
          )}
          <div>
            <StyledButton variant="contained" onClick={handleLogOut}>
              Log Out
            </StyledButton>
          </div>
        </div>
      </Box>
      {gettingOrders ? <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size={60} style={{ color: '#28a745' }} />
              </div> :
      <List style={{ position: 'relative', flexShrink: 1, maxWidth: '100%', maxHeight: '90%', overflowX: 'hidden' }}>
        {templateList.length > 0 && hasEditingPermissions && (
          <>
            <ListItem>
              <ListItemText
                primary="Templates"
                primaryTypographyProps={{
                  variant: 'h5',
                  align: 'center',
                  style: { fontWeight: 'bold' }
                }}
              />
            </ListItem>
            {templateList.map((item, index) => (
              <React.Fragment key={`template-${index}`}>
                <Divider style={{
                  width: '100%',
                  maxWidth: '100%',
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'rgb(80,80,80)',
                  backgroundColor: 'transparent',
                }} />
                <OrderListItem item={item} index={index} isTemplate={true} />

              </React.Fragment>
            ))}
            <Divider style={{
              width: '100%',
              maxWidth: '100%',
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'rgb(80,80,80)',
              backgroundColor: 'transparent',
            }} />
          </>
        )}
        {orderList.length > 0 && (
          <>
            <ListItem>
              <ListItemText
                primary="Order History"
                primaryTypographyProps={{
                  variant: 'h5',
                  align: 'center',
                  style: { fontWeight: 'bold' }
                }}
              />
            </ListItem>
            {orderList.map((item, index) => (
              <React.Fragment key={`order-${index}`}>
                <Divider style={{
                  width: '100%',
                  maxWidth: '100%',
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'rgb(80,80,80)',
                  backgroundColor: 'transparent',
                }} />
                <OrderListItem item={item} index={index} isTemplate={false} />

              </React.Fragment>
            ))}
            <Divider style={{
              width: '100%',
              maxWidth: '100%',
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'rgb(80,80,80)',
              backgroundColor: 'transparent',
            }} />
          </>
        )}
      </List>
}
    </>
  );
}

const OrderViewerComponent = ({ selectedItem, setSelectedItem, orderList, setOrderList, permissions, selectedIsTemplate, setSelectedIsTemplate, templateList, isNewOrder }) => {

  const [items, setItems] = useState([]);
  const [selectedContent, setSelectedContent] = useState([]);
  const [lastItemId, setLastItemId] = useState(0);
  const [originalItems, setOriginalItems] = useState([]);
  const [savingState, setSavingState] = useState(0); //0 default, 1 saving, 2 success, 3 failure
  const [templateSavingState, setTemplateSavingState] = useState(0); //0 default, 1 saving, 2 success, 3 failure
  const [openAddMenu, setOpenAddMenu] = useState(false);
  const [anchorAddMenu, setAnchorAddMenu] = useState(null);
  const [newSongModal, setNewSongModal] = useState(false);
  const [backModalOpen, setBackModalOpen] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [itemsWithEmptyLeaves, setItemsWithEmptyLeaves] = useState([]);
  const [templateIndex, setTemplateIndex] = useState(null);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [isRed, setIsRed] = useState(false);
  const containerRef = useRef(null);
  const listRef = useRef(null);
  const titleRef = useRef(null);

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  useEffect(() => {
    if (originalItems.length === 0) {
      setOriginalItems(JSON.parse(JSON.stringify(items)));
    }
  }, [items])

  const isVertical = viewportHeight > viewportWidth;
  const isDesktop = viewportWidth > 1200;

  useEffect(() => {
    if (selectedItem !== null) {
      let selectedContent = orderList[selectedItem];
      if (selectedIsTemplate) {
        selectedContent = templateList[selectedItem];
      }

      const updatedItems = selectedContent.content.render_pipeline.map((item, index) => ({
        ...item,
        item_id: index
      }));
      setItems(updatedItems);
      setLastItemId(updatedItems.length);
    }
  }, [selectedItem])

  function findEmptyOrNullLeaves(arr) {
    if (!Array.isArray(arr)) {
      throw new Error("Input must be an array");
    }

    function hasEmptyOrNullLeaf(obj) {
      if (obj === null || obj === "") {
        return true;
      }

      if (typeof obj !== 'object') {
        return false;
      }

      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (hasEmptyOrNullLeaf(obj[key])) {
            return true;
          }
        }
      }

      return false;
    }

    const results = [];

    for (let i = 0; i < arr.length; i++) {
      if (hasEmptyOrNullLeaf(arr[i]) || (arr[i].type === "template")) {
        results.push(arr[i].item_id);
      }
    }

    return results;
  }


  const isDrawerOpen = selectedItem !== null;

  const iOS =
    typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const hasPermission = (id) => {
    return permissions.some(item => item.id === id);
  };

  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState(null);

  function handleDelete(index, isTemplate) {
    if (deleteConfirmation && index === deleteIndex) {
      onDelete(index, isTemplate);
      setDeleteConfirmation(false);
      setDeleteIndex(null);
    }
    else {
      setDeleteConfirmation(true);
      setDeleteIndex(index);
    }

  }
  async function onDelete(index, isTemplate) {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems); //back
    setIsSaved(false);
  }

  const hasEditingPermissions = hasPermission(58);

  async function handleTemplateCreation() {
    setTemplateSavingState(1);
    const isTitleEmpty = orderList[selectedItem].title.trim() === '';
    setIsRed(isTitleEmpty);
    if (isTitleEmpty) {
      setTemplateSavingState(3)
      setTimeout(() => {
        setTemplateSavingState(0);
      }, 2000);
      return;
    }

    const newItems = items.map(obj => {
      if (obj.content && obj.content.img_srcs) {
        const { img_srcs, ...restContent } = obj.content;
        return { ...obj, content: restContent };
      }
      return obj;
    });

    const newJsonObject = {
      render_pipeline: newItems
    };

    const response = await customAxios.post('/jubilee/save_order/', { 'title': orderList[selectedItem].title, 'pk_id': orderList[selectedItem].pk, 'content': newJsonObject, 'type': 'template' });
    if (response.status === 200) {
      setTemplateSavingState(2);
      setIsSaved(true);
      setTimeout(() => {
        setTemplateSavingState(0);
        setOriginalItems(JSON.parse(JSON.stringify(items)));
        setSelectedItem(null);
      }, 2000); // 2 seconds delay
    } else {
      setTemplateSavingState(3);
      setTimeout(() => {
        setTemplateSavingState(0);
      }, 2000); // 2 seconds delay
    }
  }

  async function handleSave(isTemplate) {

    if (!isNewOrder) { //Edit button has been pressed
      if (isTemplate) { //Is a template being edited, only care about the title
        setSavingState(1);
        const isTitleEmpty = templateList[selectedItem].title.trim() === '';
        setIsRed(isTitleEmpty);
        if (isTitleEmpty) {
          setSavingState(3)
          setTimeout(() => {
            setSavingState(0);
          }, 2000);
          return;
        }

        const newItems = items.map(obj => {
          if (obj.content && obj.content.img_srcs) {
            const { img_srcs, ...restContent } = obj.content;
            return { ...obj, content: restContent };
          }
          return obj;
        });

        const newJsonObject = {
          render_pipeline: newItems
        };

        const response = await customAxios.post('/jubilee/save_order/', { 'title': templateList[selectedItem].title, 'pk_id': templateList[selectedItem].pk, 'content': newJsonObject, 'type': 'template' });
        if (response.status === 200) {
          setSavingState(2);
          setIsSaved(true);
          setTimeout(() => {
            setSavingState(0);
            setOriginalItems(JSON.parse(JSON.stringify(items)));
          }, 2000); // 2 seconds delay
        } else {
          setSavingState(3);
          setTimeout(() => {
            setSavingState(0);
          }, 2000); // 2 seconds delay
        }

      }
      else { //Is an order being edited, I care about everything
        setSavingState(1);
        const incompleteItems = findEmptyOrNullLeaves(items);
        const isTitleEmpty = orderList[selectedItem].title.trim() === '';
        setIsRed(isTitleEmpty);
        setItemsWithEmptyLeaves(incompleteItems);
        if (incompleteItems.length > 0) {
          setSavingState(3)
          setTimeout(() => {
            setSavingState(0);
          }, 2000);
          return;
        }

        const newItems = items.map(obj => {
          if (obj.content && obj.content.img_srcs) {
            const { img_srcs, ...restContent } = obj.content;
            return { ...obj, content: restContent };
          }
          return obj;
        });

        const newJsonObject = {
          render_pipeline: newItems
        };

        const response = await customAxios.post('/jubilee/save_order/', { 'title': orderList[selectedItem].title, 'pk_id': orderList[selectedItem].pk, 'content': newJsonObject, 'type': 'order' });
        if (response.status === 200) {
          setSavingState(2);
          setIsSaved(true);
          setTimeout(() => {
            setSavingState(0);
            setOriginalItems(JSON.parse(JSON.stringify(items)));
          }, 2000); // 2 seconds delay
        } else {
          setSavingState(3);
          setTimeout(() => {
            setSavingState(0);
          }, 2000); // 2 seconds delay
        }
      }
    }
    else { //This is a template being used as a new order, care about everything
      setSavingState(1);
      const isTitleEmpty = orderList[selectedItem].title.trim() === '';
      setIsRed(isTitleEmpty);
      if (isTitleEmpty) {
        setSavingState(3)
        setTimeout(() => {
          setSavingState(0);
        }, 2000);
        return;
      }

      const incompleteItems = findEmptyOrNullLeaves(items);
      setItemsWithEmptyLeaves(incompleteItems);
      if (incompleteItems.length > 0) {
        setSavingState(3)
        setTimeout(() => {
          setSavingState(0);
        }, 2000);
        return;
      }

      const newItems = items.map(obj => {
        if (obj.content && obj.content.img_srcs) {
          const { img_srcs, ...restContent } = obj.content;
          return { ...obj, content: restContent };
        }
        return obj;
      });

      const newJsonObject = {
        render_pipeline: newItems
      };

      const response = await customAxios.post('/jubilee/save_order/', { 'title': orderList[orderList.length - 1].title, 'pk_id': orderList[orderList.length - 1].pk, 'content': newJsonObject, 'type': 'order' });
      if (response.status === 200) {
        setSavingState(2);
        setIsSaved(true);
        setTimeout(() => {
          setSavingState(0);
          setOriginalItems(JSON.parse(JSON.stringify(items)));
        }, 2000); // 2 seconds delay
      } else {
        setSavingState(3);
        setTimeout(() => {
          setTemplateSavingState(0);
        }, 2000); // 2 seconds delay
      }
    }
  }

  const handleSongSelect = (newValue) => {
    if (newValue !== null) {
      let insertIndex = templateIndex !== null ? templateIndex : items.length;
      const newSong = {
        item_id: lastItemId + 1,
        content: {
          id: newValue.pk,
          preceeding_text: 'Song',
          song_number: newValue.fields.song_number,
          title: newValue.fields.title,
          verse_order: newValue.fields.verse_order
        },
        type: 'song'
      };

      const updatedItems = [...items];
      if (templateIndex !== null) {
        updatedItems[templateIndex] = newSong;
      } else {
        updatedItems.push(newSong);
      }

      setItems(updatedItems);
      setIsSaved(false);
      setLastItemId(lastItemId + 1);
      setTemplateIndex(null);

      setAutocompleteOpen(false);
    }
  };

  const AutocompleteComponent = ({ open, setOpen }) => {
    const [options, setOptions] = React.useState([]);
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState(null);
    const inputRef = useRef(null);
    const loading = open && options.length === 0;

    useEffect(() => {
      if (open) {
        getSongList();
        focusInput();
      } else {
        setOptions([]);
        setInputValue('');
        setValue(null);
      }
    }, [open]);

    const focusInput = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };


    React.useEffect(() => {
      let active = true;

      if (!loading) {
        return undefined;
      }

      return () => {
        active = false;
      };
    }, [loading]);

    async function getSongList() {
      const songList = await customAxios.get('/jubilee/get_songs/');
      const processedSongList = songList.data.songs.map(song => ({
        ...song,
        fields: {
          ...song.fields,
          title: song.fields.title.replace(/'/g, "'")
        }
      }));

      setOptions(processedSongList);
    }

    const handleInputChange = (event, newInputValue) => {
      setInputValue(newInputValue);
    };

    const handleChange = (event, newValue) => {
      setValue(newValue);
      if (newValue) {
        handleSongSelect(newValue);
        setOpen(false);
      }
    };

    const handleInputClick = (event) => {
      event.stopPropagation();
      if (!open) {
        setOpen(true);
      }
      focusInput();
    };

    return (
      <Autocomplete
        id="song-list"
        sx={{
          width: '100%',
          backgroundColor: 'white',
          borderRadius: '5px',
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: 'black', // Change outline color when focused
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: 'black', // Change label color when focused
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#28a745', // change this color to whatever you prefer
          },
        }}
        open={open}
        onOpen={() => {
          focusInput();
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        getOptionLabel={(option) => option.fields.song_number !== -1 ? `${option.fields.song_number} ${option.fields.title.replace(/'/g, "'")}` : `${option.fields.title.replace(/'/g, "'")}`}

        options={options}
        loading={loading}
        onChange={handleChange}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        value={value}
        clearOnBlur={false}
        clearOnEscape
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef}
            label="Add Song"
            onFocus={focusInput}
            onClick={handleInputClick}
            classes={CustomTextField}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            sx={{
              '& input::placeholder': {
                textAlign: 'center', // Center align placeholder text
              },
            }}
          />
        )}
      />
    );
  }

  const TitleBlock = ({ isRed, setIsRed, selectedItem, titleRef }) => {
    const [titleContent, setTitleContent] = useState(titleRef.current);
    const [editing, setEditing] = useState(false);

    useEffect(() => {
      if (!titleRef.current) {

        console.log('set title content')
        if (selectedIsTemplate) {
          titleRef.current = templateList[selectedItem]?.title || '';
        } else {
          titleRef.current = orderList[selectedItem]?.title || '';
        }
      }
    }, [])

    const handleFocus = () => {
      setEditing(true);
      setIsRed(false);
    };

    const handleBlur = () => {
      setEditing(false);
      setOrderList((prevItems) => {
        const updatedItems = [...orderList];
        updatedItems[selectedItem].title = titleRef.current;
        return updatedItems;
      });
    };

    return (
      <input
        type="text"
        value={titleContent}
        onChange={(e) => {
          setTitleContent(e.target.value);
          titleRef.current = e.target.value;
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder='Title: '
        style={{ width: 'fit-content', fontSize: '20px', backgroundColor: isRed ? 'red' : 'white' }}
      />
    );
  }

  function handleAddMenuClose() {
    setAnchorAddMenu(null);
    setOpenAddMenu(false);
  }

  function handleAddMenu(e) {
    setOpenAddMenu(!openAddMenu);
    setAnchorAddMenu(e.currentTarget);
  }

  function handleAddElement(element) {

    switch (element) {
      case 'upload':
        setNewSongModal(true);
        break;
      case 'text':
        const newText = { item_id: lastItemId + 1, content: { text: '' }, type: 'text' }
        setItems([...items, newText]);
        setIsSaved(false);
        setIsSaved(false);
        setTimeout(() => {
          listRef.current.scrollTo({ top: listRef.current.scrollHeight, behavior: 'smooth' });
        }, 0);
        setLastItemId(lastItemId + 1);
        break;
      case 'twt':
        const newTwt = { item_id: lastItemId + 1, content: { target: '', text: '' }, type: 'text_w_target' }
        setItems([...items, newTwt]);
        setIsSaved(false);
        setTimeout(() => {
          listRef.current.scrollTo({ top: listRef.current.scrollHeight, behavior: 'smooth' });
        }, 0);
        setLastItemId(lastItemId + 1);
        break;
      case 'announcement':
        const newAnnouncement = { item_id: lastItemId + 1, content: { date: '', text: '' }, type: 'announcement' }
        setItems([...items, newAnnouncement]);
        setIsSaved(false);
        setTimeout(() => {
          listRef.current.scrollTo({ top: listRef.current.scrollHeight, behavior: 'smooth' });
        }, 0);
        setLastItemId(lastItemId + 1);
        break;
      case 'song-template':
        const newSongTemplate = { item_id: lastItemId + 1, content: { type: "song" }, type: 'template' }
        setItems([...items, newSongTemplate]);
        setIsSaved(false);
        setTimeout(() => {
          listRef.current.scrollTo({ top: listRef.current.scrollHeight, behavior: 'smooth' });
        }, 0);
        setLastItemId(lastItemId + 1);
        break;
      default:
        console.log('something went wrong')
        break;
    }
    handleAddMenuClose();
  }

  const handleNewSongClose = () => {
    setNewSongModal(false);
  }

  const ModalContainerStyle = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  const ModalPaperStyle = styled('div')(({ theme }) => ({
    backgroundColor: '#F0F0F0',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 400,
  }));

  const FormControlStyle = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(1),
    minWidth: 120,
    '& .MuiInput-underline:after': {
      borderBottomColor: '#28a745',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'black',
    },
  }));

  const FileInputStyle = styled('input')({
    display: 'none',
  });

  const UploadButtonStyle = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
  }));

  const TextFieldStyle = styled(TextField)(({ theme }) => ({
    margin: theme.spacing(1),
    '& .MuiInput-underline:after': {
      borderBottomColor: '#28a745',
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'black',
    },
  }));

  const UploadConfirmationButtonStyle = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    backgroundColor: '#28a745',
    '&:hover': {
      backgroundColor: '#28a745',
    },
  }));

  const ModalContentStyle = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: 400,
    maxWidth: '80vw',
    backgroundColor: '#f0f0f0',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
  }));

  const ButtonContainerStyle = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  }));

  const KeyOptions = ['A', 'A#', 'Bb', 'B', 'C', 'C#', 'Db', 'D', 'D#', 'Eb', 'E', 'F', 'F#', 'Gb', 'G', 'G#', 'Ab'];

  const SongForm = ({ newSongModal, onClose }) => {
    const [file, setFile] = useState(null);
    const [songTitle, setSongTitle] = useState('');
    const [songNumber, setSongNumber] = useState('');
    const [keyValue, setKeyValue] = useState('');
    const [verseCount, setVerseCount] = useState('');
    const [savingState, setSavingState] = useState(0);

    const handleFileChange = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile && selectedFile.type === 'application/pdf') {
        setFile({
          file: selectedFile,
          fileName: selectedFile.name // Store the file name along with the file
        });
      } else {
        setFile(null);
        // You can show an error message here if needed
      }
    };

    async function handleUploadButtonClick() {

      const formData = new FormData();
      formData.append('title', songTitle);
      formData.append('key', keyValue);
      formData.append('verseCount', verseCount);
      formData.append('songNumber', songNumber);
      formData.append('pdfFile', file.file);

      // Send formData via Axios
      setSavingState(1);
      customAxios.post('/jubilee/submit_song/', formData)
        .then(response => {
          setSavingState(2);
          setTimeout(() => {
            setSavingState(0);
            setNewSongModal(false);
          }, 2000);
        })
        .catch(error => {
          // Handle error
          setSavingState(3);
          setTimeout(() => {
            setSavingState(0);
          }, 2000);
        });
    };

    const handleKeyChange = (event) => {
      setKeyValue(event.target.value);
    };

    return (
      <Modal
        open={newSongModal}
        onClose={onClose}
        className={ModalContainerStyle}
      >
        <ModalContainerStyle>
          <ModalContentStyle>
            <Typography variant="h5" gutterBottom>
              Upload Song
            </Typography>
            <input
              accept="application/pdf"
              style={{ display: 'none' }}
              className={FileInputStyle}
              id="contained-button-file"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<CloudUploadIcon />}
                className={UploadButtonStyle}
              >
                Upload PDF
              </Button>
              {file && (
                <Typography variant="body1" gutterBottom>
                  {file.fileName}
                </Typography>
              )}
            </label>
            <TextField
              label="Song Title"
              value={songTitle}
              onChange={(e) => setSongTitle(e.target.value)}
              fullWidth
              margin="normal"
              className={TextFieldStyle}
            />
            <TextField
              label="Song Number"
              value={songNumber}
              onChange={(e) => setSongNumber(e.target.value)}
              fullWidth
              margin="normal"
              className={TextFieldStyle}
            />
            <FormControl className={FormControlStyle} fullWidth>
              <InputLabel id="key-select-label">Key</InputLabel>
              <Select
                labelId="key-select-label"
                id="key-select"
                value={keyValue}
                onChange={handleKeyChange}
              >
                {KeyOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Verse Count"
              type="number"
              value={verseCount}
              onChange={(e) => setVerseCount(e.target.value)}
              fullWidth
              margin="normal"
              className={TextFieldStyle}
            />
            {savingState === 0 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleUploadButtonClick}
                disabled={!file || !songTitle || songNumber || !keyValue || !verseCount}
                className={UploadConfirmationButtonStyle}
              >
                Upload
              </Button>
            ) : savingState === 1 ? (
              <CircularProgress style={{ color: '#28a745' }} size={40} />
            ) : savingState === 2 ? (
              <CheckIcon style={{ color: '#28a745', fontSize: '40px' }} />
            ) : (
              <span style={{ color: 'red', fontSize: '40px' }}>X</span>
            )}
          </ModalContentStyle>
        </ModalContainerStyle>
      </Modal>
    );
  };

  const BackPopup = () => {

    return (
      <Modal
        open={backModalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Fade in={backModalOpen}>
          <ModalContentStyle>
            <h2 id="simple-modal-title">Are you sure you want to quit?</h2>
            <p id="simple-modal-description">Your changes will be lost.</p>
            <ButtonContainerStyle>
              <Button variant="contained" color="secondary" onClick={() => setSelectedItem(null)}>
                Leave
              </Button>
              <Button variant="contained" style={{ backgroundColor: '#28a745', color: '#fff' }} onClick={() => setBackModalOpen(false)}>
                Go Back
              </Button>
            </ButtonContainerStyle>
          </ModalContentStyle>
        </Fade>
      </Modal>
    );
  }

  function handleBackButton(isSaved) {
    if (!isSaved) {
      setBackModalOpen(true);
    }
    else {
      setSelectedItem(null);
    }
  }

  const [activeId, setActiveId] = useState(null);

  const onDragEnd = (result) => {

    if (!result.destination) return;

    let destinationIndex = result.destination.index;

    // Adjust destination index if it's 1 or less
    if (destinationIndex <= 1) {
      destinationIndex = 2; // Set it to 2
    }

    const newItems = [...items];
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(destinationIndex, 0, reorderedItem);

    // const newItemsWithEmptyLeaves = [...itemsWithEmptyLeaves];
    // const [reorderedLeaf] = newItemsWithEmptyLeaves.splice(result.source.index, 1);
    // newItemsWithEmptyLeaves.splice(destinationIndex, 0, reorderedLeaf);

    // console.log(itemsWithEmptyLeaves, newItemsWithEmptyLeaves)
    // setItemsWithEmptyLeaves(newItemsWithEmptyLeaves);
    setItems(newItems);
    setIsSaved(false);
  };

  const toggleAccordion = (id) => {
    setActiveId(activeId === id ? null : id);
    setItemsWithEmptyLeaves(prev => prev.filter(item => item !== id));
  };

  const handleTemplateClick = (index) => {
    setTemplateIndex(index);
    setAutocompleteOpen(true);
  };

  const ComponentFields = ({ index }) => {
    const [content, setContent] = useState(items[index].content);

    const handleFieldChange = (fieldName, value) => {
      setContent(prevContent => ({
        ...prevContent,
        [fieldName]: value
      }));
    };

    switch (items[index].type) {
      case 'header':
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type="text"
              value={content.header}
              onChange={(e) => handleFieldChange('header', e.target.value)}
              onBlur={(e) => {
                setItems(prevItems => {
                  const newItems = [...prevItems]; // Shallow copy of items
                  newItems[index] = {
                    ...newItems[index],
                    content: { ...content } // Update content at specific index
                  };
                  return newItems;
                });
                setIsSaved(false);
              }}
              placeholder="Header"
              style={{
                padding: '10px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                width: '100%',
                boxSizing: 'border-box',
                marginTop: '8px',
                marginBottom: '8px'
              }}
            />
          </div>
        );
      case 'date':
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type="text"
              value={content.date}
              onChange={(e) => handleFieldChange('date', e.target.value)}
              onBlur={(e) => {
                setItems(prevItems => {
                  const newItems = [...prevItems]; // Shallow copy of items
                  newItems[index] = {
                    ...newItems[index],
                    content: { ...content } // Update content at specific index
                  };
                  return newItems;
                });
                setIsSaved(false);
              }}
              placeholder="Date"
              style={{
                padding: '10px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                width: '100%',
                boxSizing: 'border-box',
                marginTop: '8px',
                marginBottom: '8px'
              }}
            />
          </div>
        );
      case 'song':
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type="text"
              value={content.preceeding_text}
              onChange={(e) => handleFieldChange('preceeding_text', e.target.value)}
              onBlur={(e) => {
                setItems(prevItems => {
                  const newItems = [...prevItems]; // Shallow copy of items
                  newItems[index] = {
                    ...newItems[index],
                    content: { ...content } // Update content at specific index
                  };
                  return newItems;
                });
                setIsSaved(false);
              }}
              placeholder="Song"
              style={{
                padding: '10px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                width: '100%',
                boxSizing: 'border-box',
                marginTop: '8px',
                marginBottom: '8px'
              }}
            />
            <input
              type="text"
              value={content.verse_order}
              onChange={(e) => handleFieldChange('verse_order', e.target.value)}
              onBlur={(e) => {
                setItems(prevItems => {
                  const newItems = [...prevItems]; // Shallow copy of items
                  newItems[index] = {
                    ...newItems[index],
                    content: { ...content } // Update content at specific index
                  };
                  return newItems;
                });
                setIsSaved(false);
              }}
              placeholder="Verse Order"
              style={{
                padding: '10px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                width: '100%',
                boxSizing: 'border-box',
                marginTop: '8px',
                marginBottom: '8px'
              }}
            />
          </div>
        );
      case 'text':
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type="text"
              value={content.text}
              onChange={(e) => handleFieldChange('text', e.target.value)}
              onBlur={(e) => {
                setItems(prevItems => {
                  const newItems = [...prevItems]; // Shallow copy of items
                  newItems[index] = {
                    ...newItems[index],
                    content: { ...content } // Update content at specific index
                  };
                  return newItems;
                });
                setIsSaved(false);
              }}
              placeholder="Text"
              style={{
                padding: '10px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                width: '100%',
                boxSizing: 'border-box',
                marginTop: '8px',
                marginBottom: '8px'
              }}
            />
          </div>
        );
      case 'text_w_target':
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type="text"
              value={content.text}
              onChange={(e) => handleFieldChange('text', e.target.value)}
              onBlur={(e) => {
                setItems(prevItems => {
                  const newItems = [...prevItems]; // Shallow copy of items
                  newItems[index] = {
                    ...newItems[index],
                    content: { ...content } // Update content at specific index
                  };
                  return newItems;
                });
                setIsSaved(false);
              }}
              placeholder="Text"
              style={{
                padding: '10px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                width: '100%',
                boxSizing: 'border-box',
                marginTop: '8px',
                marginBottom: '8px'
              }}
            />
            <input
              type="text"
              value={content.target}
              onChange={(e) => handleFieldChange('target', e.target.value)}
              onBlur={(e) => {
                setItems(prevItems => {
                  const newItems = [...prevItems]; // Shallow copy of items
                  newItems[index] = {
                    ...newItems[index],
                    content: { ...content } // Update content at specific index
                  };
                  return newItems;
                });
                setIsSaved(false);
              }}
              placeholder="Target"
              style={{
                padding: '10px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                width: '100%',
                boxSizing: 'border-box',
                marginTop: '8px',
                marginBottom: '8px'
              }}
            />
          </div>
        );
      case 'announcement':
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type="text"
              value={content.text}
              onChange={(e) => handleFieldChange('text', e.target.value)}
              onBlur={(e) => {
                setItems(prevItems => {
                  const newItems = [...prevItems]; // Shallow copy of items
                  newItems[index] = {
                    ...newItems[index],
                    content: { ...content } // Update content at specific index
                  };
                  return newItems;
                });
                setIsSaved(false);
              }}
              placeholder="Announcement"
              style={{
                padding: '10px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                width: '100%',
                boxSizing: 'border-box',
                marginTop: '8px',
                marginBottom: '8px'
              }}
            />
            <input
              type="text"
              value={content.target}
              onChange={(e) => handleFieldChange('date', e.target.value)}
              onBlur={(e) => {
                setItems(prevItems => {
                  const newItems = [...prevItems]; // Shallow copy of items
                  newItems[index] = {
                    ...newItems[index],
                    content: { ...content } // Update content at specific index
                  };
                  return newItems;
                });
                setIsSaved(false);
              }}
              placeholder="Date"
              style={{
                padding: '10px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                width: '100%',
                boxSizing: 'border-box',
                marginTop: '8px',
                marginBottom: '8px'
              }}
            />
          </div>
        );
    }
  }

  const getItemStyle = (isDragging, draggableStyle, index) => ({
    backgroundColor: itemsWithEmptyLeaves.includes(index) && activeId !== index
      ? 'red'
      : 'inherit',
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <Drawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      key={'drawer'}
      anchor='right'
      open={isDrawerOpen}
      onClose={() => setSelectedItem(null)}
      onOpen={null}
      style={{ backgroundColor: 'transparent', overflow: 'hidden', height: '100vh', width: '100vw' }}
    >

      <div style={{ backgroundColor: 'rgb(60,60,60)', width: '100vw', height: '100vh', overflow: 'hidden' }}>
        <AppBar position="static" style={{ backgroundColor: '#28a745' }}>
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <IconButton edge="start" color="accent" aria-label="back">
              <ArrowBackIcon onClick={() => handleBackButton(isSaved)} />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ textAlign: 'center' }}>
              <TitleBlock isRed={isRed} setIsRed={setIsRed} selectedItem={selectedItem} titleRef={titleRef} />
            </Typography>
            {hasEditingPermissions ?
              <div edge="end" style={{ display: 'flex', alignItems: 'center', gap: '1vw' }}>
                <IconButton color="accent" aria-label="back" title="Add Element" style={{ padding: 0 }}>
                  <AddIcon style={{ textAlign: 'center', margin: 0, padding: 0 }} size={40} onClick={(e) => handleAddMenu(e)} />
                </IconButton>

                <Popover
                  open={openAddMenu}
                  anchorEl={anchorAddMenu}
                  onClose={handleAddMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button onClick={() => handleAddElement('upload')}>Upload Song</Button>
                    <Button onClick={() => handleAddElement('text')}>Add Text</Button>
                    <Button onClick={() => handleAddElement('twt')}>Add Text w/Target</Button>
                    <Button onClick={() => handleAddElement('announcement')}>Add Announcement</Button>
                    <Button onClick={() => handleAddElement('song-template')}>Add Song Template</Button>
                  </div>
                </Popover>
                <IconButton color="accent" aria-label="back" title="Save Order" style={{ margin: 0, padding: 0 }} onClick={() => handleSave(selectedIsTemplate)}>
                  {savingState === 0 ? (
                    <SaveIcon />
                  ) : savingState === 1 ? (
                    <CircularProgress style={{ color: '#FFFFFF' }} size={20} />
                  ) : savingState === 2 ? (
                    <CheckIcon />
                  ) : (
                    <span style={{ color: 'red', fontSize: '20px' }}>X</span>
                  )}
                </IconButton>
                {!selectedIsTemplate ?
                  <IconButton color="accent" aria-label="custom-save" title="Save As Template" style={{ margin: 0, padding: 0 }} onClick={() => handleTemplateCreation()}>
                    {templateSavingState === 0 ? (
                      <img src="/save-file-icon.svg" alt="Custom Save" style={{ width: '24px', height: '24px' }} />
                    ) : templateSavingState === 1 ? (
                      <CircularProgress style={{ color: '#FFFFFF' }} size={20} />
                    ) : templateSavingState === 2 ? (
                      <CheckIcon />
                    ) : (
                      <span style={{ color: 'red', fontSize: '20px' }}>X</span>
                    )}
                  </IconButton>
                  : null}
              </div> : <div />}
          </Toolbar>
        </AppBar>
        <Box sx={{ p: 2, backgroundColor: 'rgb(60,60,60)', display: 'flex', flexDirection: 'column', gap: '10px' }} ref={containerRef}>
          <AutocompleteComponent open={autocompleteOpen} setOpen={setAutocompleteOpen} />

          <div style={{ height: '75vh', width: '100%', overflowY: 'scroll', scrollBehavior: 'smooth', scrollbarWidth: 'none' }} ref={listRef}>
            <DragDropContext onDragEnd={onDragEnd} enableDefaultAnimations={true}>
              <Droppable droppableId="accordion">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {items.map((draggableItem, index) => (
                      <Draggable key={`draggable-item-${draggableItem.item_id}`} draggableId={`draggable-item-${draggableItem.item_id}`} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={`accordion-item ${snapshot.isDragging ? 'dragging' : ''}`}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                              index
                            )}
                          >
                            <div className="accordion-header" style={{ display: 'flex', justifyContent: 'space-evenly', backgroundColor: itemsWithEmptyLeaves.includes(draggableItem.item_id) && activeId !== index ? 'red' : 'white' }}>
                              {
                                draggableItem.type === 'header' || draggableItem.type === 'date' ?
                                  <div></div>
                                  :
                                  <div {...provided.dragHandleProps} className="drag-handle">
                                    ☰
                                  </div>
                              }
                              <div
                                className="title"
                                onClick={() => draggableItem.type === 'template' ? handleTemplateClick(index) : null}
                              >
                                {draggableItem.type === 'header' ? (
                                  <span style={{ display: 'flex', justifyContent: 'center' }}>{draggableItem.content.header ? draggableItem.content.header : 'Header'}</span>
                                ) : null}
                                {draggableItem.type === 'date' ? (
                                  <span style={{ display: 'flex', justifyContent: 'center' }}>{draggableItem.content.date ? draggableItem.content.date : 'Date'}</span>
                                ) : null}
                                {draggableItem.type === 'template' ? (
                                  <span>Tap or Click to add a new {draggableItem.content.type}</span>
                                ) : null}
                                {draggableItem.type === 'song' ? (
                                  <span style={{ display: 'flex', gap: '10px' }}>
                                    <span style={{ fontStyle: 'italic' }}>
                                      {draggableItem.content.preceeding_text}{' '}
                                    </span>
                                    <span style={{ fontWeight: 'bolder' }}>
                                      {draggableItem.content.song_number !== -1 ? draggableItem.content.song_number : ''}
                                    </span>{' '}
                                    {draggableItem.content.title.replace(/'/g, "'")}
                                    <span>
                                      ({draggableItem.content.verse_order})
                                    </span>
                                  </span>
                                ) : null}
                                {draggableItem.type === 'text' ? (
                                  <span>{draggableItem.content.text ? draggableItem.content.text : 'Text'}</span>
                                ) : null}
                                {draggableItem.type === 'text_w_target' ? (
                                  <span>
                                    <span style={{ fontWeight: 'bolder' }}>
                                      {draggableItem.content.text ? draggableItem.content.text : 'Text'}{' '}
                                    </span>
                                    : {draggableItem.content.target ? draggableItem.content.target : 'Target'}
                                  </span>
                                ) : null}
                                {draggableItem.type === 'announcement' ? (
                                  <span>
                                    {draggableItem.content.text ? draggableItem.content.text : 'Announcement'}{' '}
                                    : {draggableItem.content.date ? draggableItem.content.date : 'Date'}
                                  </span>
                                ) : null}

                              </div>
                              <div style={{ display: 'flex', gap: '10px' }}>
                                {draggableItem.type !== 'template' && (
                                  <ChevronDown className={`arrow ${activeId === index ? 'open' : ''} animate-flip`} onClick={() => toggleAccordion(index)} />
                                )}
                                {draggableItem.type !== 'header' && draggableItem.type !== 'date' && hasEditingPermissions ?
                                  (index === deleteIndex ? <DeleteIcon onClick={(e) => handleDelete(index)} style={{ color: 'red' }} /> : <DeleteIcon onClick={(e) => handleDelete(index)} style={{}} />) : null
                                }
                              </div>
                            </div>
                            {activeId === index && (
                              <div className="accordion-content"><ComponentFields index={index} /></div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Box>
      </div>
      <SongForm newSongModal={newSongModal} onClose={handleNewSongClose} />
      <BackPopup />
    </Drawer>
  );
}